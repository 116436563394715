import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Table,
} from "reactstrap";
import PageTitle from "../../../layout/main/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faIndianRupee } from "@fortawesome/free-solid-svg-icons";
import {
  deleteMedicine,
  fetchMyMedicineRequests,
} from "../../../dataApi/medicineWindowApi";
import toast from "react-hot-toast";
import { useClinic } from "../..";
import CommonComponents from "../../common";
import AppSidebar from "../../../layout/sidebar";
import AppHeader from "../../../layout/header";

function MedicineWindow() {
  const [medicineRequestList, setMedicineRequestList] = useState([]);

  const { selectedClinic } = useClinic();

  useEffect(() => {
    if (selectedClinic) {
      fetchMedicineList();
    }
  }, [selectedClinic]);

  const fetchMedicineList = () => {
    if (selectedClinic) {
      fetchMyMedicineRequests(
        selectedClinic._id,
        (data) => {
          toast.success("Refreshed!");
          setMedicineRequestList(data);
        },
        (errorMessage) => {
          toast.error("Failed to fetch medicine requests: " + errorMessage);
          setMedicineRequestList([]);
        }
      );
    }
  };

  const handleDeleteMedicine = (id) => {
    deleteMedicine(
      id,
      (data) => {
        toast.success("Deleted!");
        fetchMedicineList();
      },
      (errorMessage) => {
        toast.error("Failed to delete medicine : " + errorMessage);
      }
    );
  };

  return (
    <Fragment>
      <CommonComponents />
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <TransitionGroup>
              <CSSTransition
                component="div"
                classNames="TabsAnimation"
                appear={true}
                timeout={1500}
                enter={false}
                exit={false}
              >
                <div>
                  <PageTitle
                    heading="Medicine window"
                    subheading="Medicine details for patients prescribed by doctor."
                    icon="pe-7s-note2 icon-gradient bg-tempting-azure"
                  />

                  <div className="d-flex flex-wrap">
                    {!medicineRequestList || medicineRequestList.length <= 0 ? (
                      <div className="text-danger">No medicines pending</div>
                    ) : (
                      medicineRequestList.map((mr) => (
                        <Card
                          // className="my-2"
                          color="primary"
                          outline
                          style={
                            {
                              // width: '20rem'
                            }
                          }
                          className="d-flex flex-wrap m-1"
                        >
                          <CardHeader>
                            <div>{mr.patientName}</div> -
                            <div>+91-{mr.contactNumber}</div>
                          </CardHeader>
                          <CardBody>
                            <CardText>
                              <Table
                                bordered
                                borderless
                                hover
                                responsive
                                striped
                              >
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Dose</th>
                                    <th>Total</th>
                                    <th>Direction</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {mr.prescriptionDetails.map((pd, index) => (
                                    <tr>
                                      <th scope="row">{index + 1}</th>
                                      <td>{pd.name.join(" + ")}</td>
                                      <td>{`${pd.morning || 0} - ${
                                        pd.afternoon || 0
                                      } - ${pd.night || 0} - ${
                                        pd.days || 0
                                      } Days`}</td>
                                      <td>
                                        {((pd.morning || 0) +
                                          (pd.afternoon || 0) +
                                          (pd.night || 0)) *
                                          (pd.days || 0)}
                                      </td>
                                      <td>
                                        <div className="text-primary">
                                          {pd.note && pd.note}
                                        </div>
                                        <div className="text-info">
                                          {pd.beforeMeals && "Before meals"}
                                        </div>
                                        <div className="text-primary">
                                          {pd.emptyStomach && "Empty stomach"}
                                        </div>
                                        <div className="text-danger">
                                          {pd.withWarmWater &&
                                            "With warm water"}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </CardText>
                          </CardBody>
                          <CardFooter className="justify-content-between">
                            <Button
                              color="warning"
                              onClick={() => handleDeleteMedicine(mr._id)}
                            >
                              Mark Done{" "}
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="ms-1"
                              />
                            </Button>
                            {mr.billAmount && (
                              <div>
                                <strong>
                                  {" "}
                                  Please collect :{" "}
                                  <FontAwesomeIcon icon={faIndianRupee} />{" "}
                                  {mr.billAmount}
                                </strong>
                              </div>
                            )}
                          </CardFooter>
                        </Card>
                      ))
                    )}
                  </div>
                  {/* 
            <DataTable
              columns={columns}
              data={medicineRequestList}
              pagination
            /> */}
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
          {/* <AppFooter /> */}
        </div>
      </div>
    </Fragment>
  );
}

export default MedicineWindow;
