import React from "react";
import {
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Input as StrapInput,
} from "reactstrap";
import withFormikField from "./FormikField";
import moment from "moment/moment";

export function DateField(props) {
  return (
    <FormGroup>
      {props.label && <Label for={props.name}>{props.label}</Label>}
      <InputGroup>
        {props.prependElement}
        {props.prependText && (
          <InputGroupText addonType="prepend">
            {props.prependText}
          </InputGroupText>
        )}
        <StrapInput
          type={props.type || "date"}
          name={props.name}
          id={props.id || props.name}
          placeholder={props.placeholder}
          onChange={(event) => {
            props.onChange(event);
          }}
          min={moment().format("YYYY-MM-DD")}
          onBlur={props.onBlur}
          invalid={props.invalid}
          disabled={props.disabled}
          value={moment(props.value).format("YYYY-MM-DD")}
        />
      </InputGroup>
    </FormGroup>
  );
}

export default withFormikField(DateField);
