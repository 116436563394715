import React from "react";
import {
  FormGroup,
  Label,
  Input as StrapInput,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import withFormikField from "./FormikField";

export function InputField(props) {
  return (
    <FormGroup>
      {props.label && <Label for={props.name}>{props.label}</Label>}
      <InputGroup>
        {props.prependElement}
        {props.prependText && (
          <InputGroupText addonType="prepend">
            {props.prependText}
          </InputGroupText>
        )}
        <StrapInput
          type={props.type || "text"}
          name={props.name}
          id={props.id || props.name}
          className={props.className}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.onBlur}
          invalid={props.invalid}
          value={props.value}
          disabled={props.disabled}
          size={props.size}
        />
        {props.appendElement}
        {props.appendText && (
          <InputGroupText addonType="append">{props.appendText}</InputGroupText>
        )}
      </InputGroup>
    </FormGroup>
  );
}

export default withFormikField(InputField);
