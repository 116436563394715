import React, {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import cx from "classnames";
import { withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";

import AppMain, { useLoading } from "../layout/main/AppMain";
import toast from "react-hot-toast";
import axios from "axios";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import LoginPage from "./login/LoginPage";
import SignupPage from "./login/SignupPage";
import {
  fetchMyDoctors,
  fetchProfile,
  fetchProfilePic,
} from "../dataApi/userApi";
import { fetchOptionTable } from "../dataApi/optionTableApi";
import Themeoptions from "../layout/themeoptions";
import sideBar6 from "../assets/utils/images/sidebar/city1.jpg";
import { fetchMyClinics } from "../dataApi/clinicApi";
import { OPTION_TABLES } from "../constants";

const ThemeOptionsContext = createContext();
export const useTheme = () => {
  return useContext(ThemeOptionsContext);
};

const AuthContext = createContext();
export const useAuth = () => {
  return useContext(AuthContext);
};

export const UserProfileContext = createContext();
export const useUserProfile = () => {
  return useContext(UserProfileContext);
};

export const UserProfilePicContext = createContext();
export const useUserProfilePic = () => {
  return useContext(UserProfilePicContext);
};

const OptionTableContext = createContext();
export const useOptionTable = () => {
  return useContext(OptionTableContext);
};

const ClinicContext = createContext();
export const useClinic = () => {
  return useContext(ClinicContext);
};

const DoctorsContext = createContext();
export const useDoctors = () => {
  return useContext(DoctorsContext);
};

function Main(props) {
  const [token, setToken_] = useState(localStorage.getItem("token"));
  const [userProfile, setUserProfile] = useState(null);
  const [userProfilePic, setUserProfilePic] = useState(null);

  const [selectedClinic, changeSelectedClinic] = useState(null);
  const [clinics, setClinics] = useState(null);
  const [doctors, setDoctors] = useState(null);

  const [optionTables, setOptionTables] = useState({});
  const [showThemeSetting, setShowThemeSetting] = useState(false);
  const [themeOptions, setThemeOptions] = useState({
    backgroundColor: "",
    headerBackgroundColor: "",
    enableMobileMenuSmall: "",
    enableBackgroundImage: false,
    enableClosedSidebar: false,
    enableFixedHeader: true,
    enableHeaderShadow: true,
    enableSidebarShadow: true,
    enableFixedFooter: true,
    enableFixedSidebar: true,
    colorScheme: "white",
    backgroundImage: sideBar6,
    backgroundImageOpacity: "opacity-06",
    enablePageTitleIcon: true,
    enablePageTitleSubheading: true,
    enablePageTabsAlt: true,
  });

  const contextValue = useMemo(
    () => ({
      token,
      setToken_,
    }),
    [token]
  );

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem("token", token);
      refreshProfile();
      refreshDoctors();
    } else {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
    }
  }, [token]);

  useEffect(() => {
    const storedClinic = sessionStorage.getItem("CLINIC");
    if (storedClinic) {
      const parsedClinic = JSON.parse(storedClinic);
      if (clinics?.findIndex((d) => d._id == parsedClinic._id) > -1) {
        changeSelectedClinic(parsedClinic);
      }
    }
  }, [clinics]);

  const setSelectedClinic = (clinicValue) => {
    sessionStorage.setItem("CLINIC", JSON.stringify(clinicValue));
    changeSelectedClinic(clinicValue);
  };

  const getClinicName = (clinicId) => {
    const clinic = clinics?.find((c) => c._id === clinicId);
    return clinic?.name;
  };

  const getClinic = (clinicId) => {
    const clinic = clinics?.find((c) => c._id === clinicId);
    return clinic;
  };

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (token && userProfile) {
      refreshOptionTable();

      fetchMyClinics(
        (data) => {
          setClinics(data);
        },
        (errorMessage) => {
          toast("Failed to fetch clinics: " + errorMessage);
          setClinics([]);
        }
      );
    }
  }, [token, userProfile]);

  const refreshOptionTable = () => {
    fetchOptionTable(
      null,
      null,
      (data) => {
        const mergedData = {};
        data.forEach((item) => {
          const { name, options } = item;

          if (!mergedData[name]) {
            mergedData[name] = { ...item };
          } else {
            mergedData[name].options = [
              ...mergedData[name].options,
              ...options,
            ];
          }
        });

        if (
          mergedData &&
          mergedData[OPTION_TABLES.TABLETS] &&
          mergedData[OPTION_TABLES.TABLETS].options
        ) {
          mergedData[OPTION_TABLES.MEDICINES].options = [
            ...mergedData[OPTION_TABLES.MEDICINES].options,
            ...mergedData[OPTION_TABLES.TABLETS].options.map((op) => {
              return { type: OPTION_TABLES.TABLETS, value: op };
            }),
          ];
        }

        setOptionTables(mergedData);
      },
      (err) => {
        //error
      }
    );
  };

  const refreshProfile = () => {
    fetchProfile(
      (data) => {
        setUserProfile(data);
      },
      (err) => {
        //addError
      }
    );
    fetchProfilePic(
      (data) => {
        setUserProfilePic(data);
      },
      (err) => {}
    );
  };
  const refreshDoctors = () => {
    fetchMyDoctors(
      (data) => {
        setDoctors(data);
      },
      (errorMessage) => {
        toast.error("Failed to fetch doctors: " + errorMessage);
        setDoctors([]);
      }
    );
  };

  const getOptionTable = (optionsName) => {
    return optionTables && optionTables[optionsName]
      ? optionTables[optionsName].options
      : null;
  };

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = themeOptions;

  return (
    <ThemeOptionsContext.Provider
      value={{
        themeOptions: themeOptions,
        setThemeOptions: setThemeOptions,
        showThemeSetting,
        setShowThemeSetting,
      }}
    >
      <AuthContext.Provider value={contextValue}>
        <UserProfileContext.Provider value={{ userProfile, refreshProfile }}>
          <UserProfilePicContext.Provider value={userProfilePic}>
            <DoctorsContext.Provider value={{ doctors, refreshDoctors }}>
              <ClinicContext.Provider
                value={{
                  selectedClinic,
                  setSelectedClinic,
                  clinics,
                  setClinics,
                  getClinicName,
                  getClinic,
                }}
              >
                <OptionTableContext.Provider
                  value={{
                    optionTables,
                    setOptionTables,
                    getOptionTable,
                    refreshOptionTable,
                  }}
                >
                  <Themeoptions />
                  <ResizeDetector
                    handleWidth
                    render={({ width }) => (
                      <Fragment>
                        <div
                          className={cx(
                            "app-container app-theme-" + colorScheme,
                            { "fixed-header": enableFixedHeader },
                            {
                              "fixed-sidebar":
                                enableFixedSidebar || width < 1250,
                            },
                            { "fixed-footer": enableFixedFooter },
                            {
                              "closed-sidebar":
                                enableClosedSidebar || width < 1250,
                            },
                            {
                              "closed-sidebar-mobile":
                                closedSmallerSidebar || width < 1250,
                            },
                            { "sidebar-mobile-open": enableMobileMenu },
                            { "body-tabs-shadow-btn": enablePageTabsAlt }
                          )}
                        >
                          <AppMain />
                          <Route exact path="/login" component={LoginPage} />
                          <Route exact path="/signup" component={SignupPage} />
                        </div>
                      </Fragment>
                    )}
                  />
                </OptionTableContext.Provider>
              </ClinicContext.Provider>
            </DoctorsContext.Provider>
          </UserProfilePicContext.Provider>
        </UserProfileContext.Provider>
      </AuthContext.Provider>
    </ThemeOptionsContext.Provider>
  );
}

export default withRouter(Main);
