import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import AppHeader from "../../layout/header";
import AppSidebar from "../../layout/sidebar";
import AppFooter from "../../layout/footer";
import MainDashboard from "./main/MainDashboard";
const Dashboards = ({ match }) => (
  <Fragment>
    {/* <ThemeOptions /> */}
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route path={`${match.url}/main`} component={MainDashboard} />
        </div>
        <AppFooter />
      </div>
    </div>
  </Fragment>
);

export default Dashboards;
