import React, { Component, Fragment } from "react";

import PageTitle from "../../../layout/main/PageTitle";
import { Col, Progress, Row } from "reactstrap";
import Tabs, { TabContent, TabPane } from "rc-tabs";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

export default class MainDashboard extends Component {
  render() {
    return (
      <Fragment>
        <div>
          <PageTitle
            heading="Dashboard"
            subheading="All the statistics of the clinic will shown here"
            icon="pe-7s-graph icon-gradient bg-ripe-malin"
          />

          <Tabs
            defaultActiveKey="1"
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent />}
          >
            <TabPane tab="This Month" key="1">
              <Row>
                <Col md="6" xl="4">
                  <div className="card mb-3 widget-content">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Total Patients</div>
                          <div className="widget-subheading">
                            Total attended patients this month
                          </div>
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-success">
                            1896
                          </div>
                        </div>
                      </div>
                      <div className="widget-progress-wrapper">
                        <Progress
                          className="progress-bar-sm"
                          color="primary"
                          value="71"
                        />
                        <div className="progress-sub-label">
                          <div className="sub-label-left">
                            Compared to last month
                          </div>
                          <div className="sub-label-right">71%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6" xl="4">
                  <div className="card mb-3 widget-content">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Total Panchkarma</div>
                          <div className="widget-subheading">
                            Total Panchkarma therapies till date
                          </div>
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-warning">35</div>
                        </div>
                      </div>
                      <div className="widget-progress-wrapper">
                        <Progress
                          className="progress-bar-sm progress-bar-animated-alt"
                          color="danger"
                          value="85"
                        />
                        <div className="progress-sub-label">
                          <div className="sub-label-left">
                            Compared to last month
                          </div>
                          <div className="sub-label-right">85%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6" xl="4">
                  <div className="card mb-3 widget-content">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Total Revenue</div>
                          <div className="widget-subheading">
                            Total revenue this month
                          </div>
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-danger">
                            ₹ 1,25,450.00
                          </div>
                        </div>
                      </div>
                      <div className="widget-progress-wrapper">
                        <Progress
                          className="progress-bar-sm progress-bar-animated-alt"
                          color="success"
                          value="46"
                        />
                        <div className="progress-sub-label">
                          <div className="sub-label-left">
                            Compared to last month
                          </div>
                          <div className="sub-label-right">46%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6" xl="4" className="d-xl-none d-lg-block">
                  <div className="card mb-3 widget-content">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Income</div>
                          <div className="widget-subheading">
                            Expected totals
                          </div>
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-focus">$147</div>
                        </div>
                      </div>
                      <div className="widget-progress-wrapper">
                        <Progress
                          className="progress-bar-sm progress-bar-animated-alt"
                          color="info"
                          value="54"
                        />
                        <div className="progress-sub-label">
                          <div className="sub-label-left">Expenses</div>
                          <div className="sub-label-right">100%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="This Year" key="2">
              {/* <CRMDashboard1 /> */}
            </TabPane>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}
